import React from 'react';
import { Container } from 'react-bootstrap';
import './App.css';
import image from "./assets/images/REMIRA_Logo.png"
import rocket from "./assets/images/remira-rocket.png"
import { Box, Button, Logo, Input } from '@remira/unifiedui';

function App() {
	return (
		<div className="bg">
			<Container>
				<div className="d-flex flex-column">
					<div className="flex-grow-1">
						<div className="container">
							<Box className="p-5">
								<div className="d-flex justify-content-center py-2">
									<Logo image={image} style={{ width: '25%' }} />
								</div>
								<hr />
								<div className="row">
									<div className="col-md-8">
										<h3 className="text-center mt-4">Currently down for maintenance</h3>
										<p>
											Sorry for the inconvenience but we're performing some scheduled maintenance. We expect to be back soon. Thanks for your patience and please come back later.
										</p>
										{/* <p>
											Sorry for the inconvenience but we're performing some scheduled maintenance. We expect to be back in a couple hours. Thanks for your patience. Enter your email address to get notified or come back later.
										</p> */}

										{/* May be used in a future iteration */}
										{/* <p className="mb-5">
											<b>Estimated downtime:</b> &#123;countdown&#125;
										</p>
										<div className="row">
											<div className="col-md-10">
												<Input
													icon="at"
													placeholder="Email address"
													name="email"
													value=""
												/>
											</div>
											<div className="col-md-2"><Button
												label="Notify me"
												color="primary"
												fullWidth
											/></div>
										</div> */}
									</div>
									<div className="col-md-4">
										<div className="d-flex justify-content-center py-2">
											<Logo image={rocket} style={{ width: '75%' }} />
										</div>
									</div>
								</div>
							</Box>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
}

export default App;
